import React, { useState, useRef, Fragment } from "react";
import { Modal, message, Button } from "antd";
import { useTranslation } from "react-i18next";
import DetailDrawerAdd from "./components/DetailDrawerAdd";
import DetailDrawerInfo from "./components/DetailDrawerInfo";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { getVisAuthList, updateVisAuth } from "@/api/vis";
import { ProTableCustom } from "zlink-front";

const VisitorAuth = (props) => {
  const { t } = useTranslation();
  const [drawerAddStatus, setDrawerAddStatus] = useState(false);
  const [drawerInfoStatus, setDrawerInfoStatus] = useState(false);
  const [isModalAuthCodeVisible, setIsModalAuthCodeVisible] = useState(false);
  const [isAuthCode, setIsAuthCode] = useState("");
  const [visAuthId, setVisAuthId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: t("vis.visitorAuth.name"),
      key: "visName",
      dataIndex: "visName",
      hideInSearch: false,
      width: "10%",
    },
    {
      title: t("vis.visitorAuth.authorizationPeriod"),
      key: "authorizationPeriod",
      dataIndex: "authorizationPeriod",
      hideInSearch: true,
      width: "20%",
      renderText: (text, record) =>
        moment(record.authStartTime).format("YYYY-MM-DD HH:mm") +
        " ~ " +
        moment(record.authEndTime).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("vis.visitorAuth.authorizationDevices"),
      key: "visitorAuthDeviceList",
      dataIndex: "visitorAuthDeviceList",
      hideInSearch: true,
      width: "30%",
      ellipsis: true,
      renderText: (text) => {
        let authorizationDevices = "";
        for (let d of text) {
          authorizationDevices += d.deviceName + ",";
        }
        return authorizationDevices;
      },
    },
    {
      title: t("vis.visitorAuth.status"),
      key: "enable",
      dataIndex: "enable",
      hideInSearch: true,
      width: "10%",
      ellipsis: true,
      renderText: (text, record) => {
        if (!!text && moment(record.authEndTime) > Date.now()) {
          return t("vis.visitorAuth.statusNormal");
        } else {
          return t("vis.visitorAuth.statusExpired");
        }
      },
    },
    {
      title: t("common.table.option"),
      key: "operation",
      dataIndex: "operation",
      hideInSearch: true,
      width: "20%",
      render: (text, record, _, action) => [
        <Button
          type="link"
          key="viewAuthorization"
          onClick={() => {
            setVisAuthId(record.id);
            setDrawerInfoStatus(true);
          }}
        >
          {t("vis.visitorAuth.viewAuthorization")}
        </Button>,
        <>
          {(!record?.enable || moment(record?.authEndTime) > Date.now()) && (
            <Button
              type="link"
              key="oneClickFailure"
              onClick={() => { setVisAuthId(record.id); setIsModalVisible(true) }}
            >
              {t("vis.visitorAuth.oneClickFailure")}
            </Button>
          )}
        </>,
      ],
    },
  ];

  const actionRef = useRef();

  const closeAddDrawer = () => {
    setDrawerAddStatus(false);
  };

  const closeInfoDrawer = () => {
    setDrawerInfoStatus(false);
  };

  const handleIsModalAuthCodeVisible = (code) => {
    setIsAuthCode(code);
    setIsModalAuthCodeVisible(true);
  };

  const handleIsModalAuthCodeNoVisible = () => {
    setIsModalAuthCodeVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  /* ===================调用退卡api ===================*/
  const handleUpdateVisAuth = async (visAuthId, params, callback) => {
    const result = await updateVisAuth(visAuthId, params);
    if (undefined !== callback) {
      return callback(result);
    }
  };

  const handleOk = () => {
    var params = {
      enable: false,
    };
    handleUpdateVisAuth(visAuthId, params, function (result) {
      setIsModalVisible(false);
      message.success(t("vis.visitorAuth.oneClickFailure") + t("common.result.opSuccess"));
      refreshTable();
    });
  };

  const refreshTable = () => {
    actionRef.current?.reloadAndRest?.();
  };
  return (
    <Fragment>
      <ProTableCustom
        columns={columns}
        actionRef={actionRef}
        request={async (params = {}, sort, filter) => {
          // TODO   暂存为ID   params?.miRoleCode
          const res = await getVisAuthList({
            pageNumber: params.current,
            pageSize: params.pageSize,
            visName: params?.searchParams || "",
          });

          return {
            data: res.rows,
            success: true,
            total: res.totalConunt,
          };
        }}
        searchPlaceholder={t("common.table.searchPlaceholder")}
        rowKey="id"
        toolBar={[
          <Button
            key="button"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setDrawerAddStatus(true);
            }}
          >
            {t("common.button.add")}
          </Button>,
        ]}
      />

      {drawerAddStatus && (
        <DetailDrawerAdd
          visible={drawerAddStatus}
          close={closeAddDrawer}
          setIsModalAuthCodeVisible={handleIsModalAuthCodeVisible}
          reload={() => actionRef.current.reload()}
        />
      )}

      {drawerInfoStatus && (
        <DetailDrawerInfo
          visible={drawerInfoStatus}
          close={closeInfoDrawer}
          visAuthId={visAuthId}
          reload={() => actionRef.current.reload()}
        />
      )}

      <Modal
        title={t("common.text.tips")}
        open={isModalAuthCodeVisible}
        onCancel={handleIsModalAuthCodeNoVisible}
        footer={[
          <Button key="back" onClick={handleIsModalAuthCodeNoVisible}>
            {t("common.button.sure")}
          </Button>,
        ]}
      >
        <p> {t("vis.visitorAuth.succeedAuth")} </p>
        <p>
          {t("vis.visitorAuth.password")}: {isAuthCode}{" "}
        </p>
      </Modal>

      <Modal
        title={t("common.text.tips")}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p> {t("vis.visitorAuth.tipsOpOneClickFailure")} </p>
      </Modal>
    </Fragment>
  );
};

export default VisitorAuth;
